export default {
  data: function() {
    return {
      id: Go.uuid(),
    };
  },
  methods: {
    togglePassword: function() {
      Go.toggleAttributeValues("[name=userPass]", "type", ["password", "text"]);
      Go.toggleAttributeValues("[name=password]", "type", ["password", "text"]);
      Go.toggleAttributeValues(".InputLabelDouble._pass", "type", ["password", "text"]);
    },
    userRememberme: function() {
      const isRemember = document.querySelector(`.userRememberme [name="rememberme"]`).checked;

      if (!isRemember) return;

      const user = {
        userName: this.base64_encode(document.querySelector(`.login-section-form-data [name="userName"]`).value),
        userPass: this.base64_encode(document.querySelector(`.login-section-form-data [name="userPass"]`).value),
      };

      this.payload({ user });
    },
  },
  computed: {
    savedUserName: function() {
      const user = this.$payload && this.$payload.user;
      if (!user) return;
      return this.base64_decode(user.userName);
    },
    savedUserPass: function() {
      const user = this.$payload && this.$payload.user;
      if (!user) return;
      return this.base64_decode(user.userPass);
    },
    isRememberme: function() {
      return this.$payload.user && this.$payload.user.userName;
    },
  },
};
