<template>
  <div class="login-section-form-data">
    <form id="signup-data-form" v-if="$locale" :key="id" v-on:submit.prevent>
      <div class="block-input">
        <label>{{ $locale["input-email"] }}</label>
        <input
          type="email"
          name="email"
          :placeholder="$locale['input-email-placeholder']"
          required
          @blur="validateInput($event, { tooltip: false })"
        />
      </div>
      <div class="block-input">
        <label>{{ $locale["input-user-name"] }}</label>
        <input
          type="text"
          name="user"
          :placeholder="$locale['input-user-name-placeholder']"
          required
          @blur="validateInput($event, { tooltip: false })"
        />
      </div>
      <div class="block-input">
        <div class="InputLabelDouble _pass" type="password">
          <label ellipsis for="userPass">{{ $locale["input-password"] }}</label>
          <a class="ShowPassButton" @click="togglePassword">
            <Icon class="password" name="eye" />
            <Icon class="text" name="eye-slash" />
          </a>
        </div>
        <input
          type="password"
          name="password"
          :placeholder="$locale['input-password-placeholder']"
          required
          @blur="validateInput($event, { tooltip: false })"
          @keydown.enter="sendData('login-data-form')"
        />
      </div>
      <div class="block-input" v-if="ageAccepted === true">
        <label>{{ $locale["object"]["user-profile"]["input-birthday-label"] }}</label>
        <input
          v-calendar
          type="date"
          name="birthday"
          :placeholder="$locale['birthday']"
          required
          @blur="validateInput($event, { tooltip: false })"
          @keydown.enter="sendData('login-data-form')"
        />
      </div>
      <div class="block-input">
        <input type="nosubmit" :value="$locale['input-signup-btn']" readonly @click="sendData('signup-data-form')" />
      </div>
    </form>
  </div>
</template>

<script>
import UserCommon from "./UserCommon";
export default {
  mixins: [UserCommon],
  methods: {
    elements: function(el) {
      const elmns = {
        form: document.querySelector("#signup-data-form"),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    validateInput: function($event, $config) {
      return this.$validate.validateInput($event, $config);
    },
    isValidUser: function(formID) {
      const userName = this.elements(`#${formID} input[name="user"]`)?.value;
      return this.$global.validUserName(userName);
    },
    sendData: function(formID) {
      const isValidForm = this.$validate.validateForm(`#${formID}`, { tooltip: false });
      if (!isValidForm) {
        //Check Data Silent
      } else if (!this.isValidUser(formID)) {
        this.elements(`#${formID} input[name="user"]`)?.classList.add("data-error");
        this.showMessage({ icon: "ui_error", message: this.$locale["invalid-usename"], type: "error", title: "Error" });
      } else if (!this.ageAccepted) {
        this.$store.commit("setAgeAccepted", "confirm");
      } else if (isValidForm) {
        this.ialert.go({ type: "loader" });
        const _data = new FormData(document.getElementById(formID));
        const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "user/register", _data);
        sedData.then((response) => this.sendSuccess(response));
        sedData.catch((response) => this.sendError(response));
      }
    },
    sendSuccess: function({ data = {} } = {}) {
      if (data && data.success) {
        this.$store.dispatch("updateUser", data);
        this.showMessage({ icon: "success_animated", message: data.message, type: "success", title: data.title });
        this.$router.push("/").catch(() => {});
      } else {
        this.showMessage({ icon: "ui_error", message: data.message, type: "error", title: data.title });
      }
      this.ialert.close();
    },
    sendError: function(response) {
      this.ialert.close();
      this.showMessage({ icon: "ui_error", message: response.toString(), type: "error", title: "Error" });
    },
  },
  computed: {
    ageAccepted: function() {
      return this.$store.state.user.ageAccepted;
    },
  },
};
</script>
